import React, { lazy } from 'react'

const NotFound = lazy(() =>
  import('./index').then(module => ({
    default: module.NotFound,
  }))
)
export const PUBLIC_PATH = {

  NOT_FOUND_404: '/not-found-404',
  SERVICE:'/Services'
}
const PublicRoutes = [
 
  {
    props: { exact: true, path: PUBLIC_PATH.NOT_FOUND_404},
    component: <NotFound />,
  },

]

export default PublicRoutes
