import React, { lazy } from 'react'

// const OCRTest = lazy(() =>
//   import('./OCRTest').then(module => ({
//     default: module.OCRTest,
//   }))
// )
export const ACCOUNT_PATH = {
  PROFILE: '/account/profile',
  ABOUT_US: '/account/about-us',
  CHANGE_PASSWORD: '/account/change-password',
  OCR: '/account/ocr-test',
}
const AccountRoutes = [

  // {
  //   props: { exact: true, path: ACCOUNT_PATH.OCR },
  //   component: <OCRTest />,
  // },
]

export default AccountRoutes
