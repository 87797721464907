import React, { ReactNode } from 'react'
import { FC } from 'react'
import { Redirect, Route } from 'react-router-dom'
type Props = {
  children?: ReactNode
}

export const PrivateRoute: FC<Props> = ({ children, ...rest }) => {
  const AccessToken = JSON.parse(localStorage.getItem('GuestLoginInfo'))
    ?.accessToken

  return (
    <Route
      {...rest}
      render={({ location }) =>
     (
          children
        ) 
      }
    />
  )
}
